@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

body {
  box-sizing: border-box;
  height: 100%;
  width: 100%; }

html {
  font-size: 62.5%;
  height: 100%; }

.row {
  margin-top: 5rem; }

#cover {
  background: url("../images/banner.jpg") center center no-repeat;
  color: white;
  background-size: cover;
  height: 75vh;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center; }

#cover-caption {
  width: 100vw; }

.navbar {
  font-size: 2rem; }
  .navbar .username {
    color: grey;
    padding-right: 5rem; }

.xrptipbot {
  margin-top: 3rem;
  text-align: center; }
  .xrptipbot .donate {
    font-size: 2rem; }

.challengeBoard__title {
  text-align: center;
  font-size: 3rem;
  margin-top: 2rem; }

.parentChallengeBoard {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 7rem 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px; }

.challengeBoard_YourStats {
  font-size: 3rem;
  grid-area: 1 / 1 / 2 / 2; }

.challengeBoard_TheirStats {
  font-size: 3rem;
  grid-area: 1 / 2 / 2 / 3; }

.challengeBoard_YourStatNumbers {
  font-size: 2rem;
  grid-area: 2 / 1 / 3 / 2; }

.challengeBoard_TheirStatNumbers {
  font-size: 2rem;
  grid-area: 2 / 2 / 3 / 3; }

.leaderboard {
  font-size: 2rem; }

body {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: left;
  background-color: #f5f8fa; }

.navbar-laravel {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04); }

.navbar-brand,
.nav-link,
.my-form,
.login-form {
  font-family: Raleway, sans-serif; }

.my-form {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.my-form .row {
  margin-left: 0;
  margin-right: 0; }

.login-form {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.login-form .row {
  margin-left: 0;
  margin-right: 0; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%; }

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite; }

.loading-bar:nth-child(1) {
  background-color: #3498db;
  animation-delay: 0; }

.loading-bar:nth-child(2) {
  background-color: #c0392b;
  animation-delay: 0.09s; }

.loading-bar:nth-child(3) {
  background-color: #f1c40f;
  animation-delay: 0.18s; }

.loading-bar:nth-child(4) {
  background-color: #27ae60;
  animation-delay: 0.27s; }

@keyframes loading {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(1, 2.2); }
  40% {
    transform: scale(1); } }

.marketBetWrapper {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center; }

.marketBet__Summary {
  font-size: 2rem;
  grid-area: 1 / 2 / 2 / 3; }

.marketBet__amount {
  font-size: 2rem;
  grid-area: 2 / 2 / 3 / 3; }

.marketBet__Button {
  font-size: 2rem;
  grid-area: 3 / 2 / 4 / 3; }

.marketBetLeft {
  min-width: 10vw;
  grid-area: 1 / 1 / 4 / 2; }

.marketBetRight {
  min-width: 10vw;
  grid-area: 1 / 3 / 4 / 4; }

.profile {
  padding-top: 2rem;
  text-align: center; }
  .profile .profile__username {
    padding: 2rem;
    font-weight: bold; }
  .profile .profile__friends {
    font-size: 3rem; }
  .profile .profile__friend {
    padding: 0.5rem;
    font-size: 1.5rem; }
  .profile .profile__friendButton {
    margin-left: 1rem;
    padding: 0.25rem; }
  .profile .profile__recentGames {
    padding-top: 2rem;
    font-size: 3rem; }
  .profile .profile__stat {
    padding: 0.5rem;
    font-size: 1.5rem; }
